<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 bgExampleClass">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex login align-items-center">
        <b-link class="brand-logo">
          <b-img
            fluid
            :src="logoImg"
            alt="Login V2"
            style="width: 96px; height: 96px"
          />
          <!-- <h2 class="brand-text text-primary ml-1">Hatchery</h2> -->
        </b-link>
        <div
          class="w-100 d-lg-flex flex-column align-items-center justify-content-center"
        >
          <h4 class="loginText">
            Manage your poultry operations effectively, make well-informed
            decisions, and precisely forecast
          </h4>
          <b-img
            fluid
            :src="sideImg"
            alt="Login V2"
            style="width: 700px; height: 400px"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center login px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- <b-card-title
						class="mb-1 headText"
						title-tag="h2"
						style="color: #38c06c"
					>
						Poultrac-Tag-a-Shed !
					</b-card-title> -->
          <b-card-title class="mb-1 headText" title-tag="h2">
            Welcome to Hatchery !
          </b-card-title>
          <b-card-text class="mb-2 textInfo">
            Please sign-in to your account
          </b-card-text>
          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email or Username" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <button class="col-12 privacy-policy" @click="privacy()">
        <i class="fa fa-search"></i>Privacy policy *
      </button>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import axios from "axios";
import pages from "@/router/routes/pages";
import apps from "@/router/routes/apps";
import others from "@/router/routes/others";
import company_apps from "@/router/routes/company_apps";
import dashboard from "@/router/routes/dashboard";
import company_dashboard from "@/router/routes/company_dashboard";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    others,
    pages,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/login/login-left.png"),
      logoImg: require("@/assets/images/login/logo.png"),
      // validation rules
      required,
      email,
      rowData: [],
      // loginImage: {
      //   backgroundImage: require("@/assets/images/login/login.png"),
      // },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/login/login-left.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              useJwt.setToken(response.data.data.token);
              const userData = response.data.data.user;
              const role = response.data.data.user.roles;
              localStorage.setItem("user-role", role);
              userData.role = role;
              localStorage.setItem("userData", JSON.stringify(userData));
              window.location = "/";
              // this.$router.removeRoute(apps.map((v) => v?.name));
              // this.$router.removeRoute(company_apps.map((v) => v?.name));
              // this.$router.removeRoute(company_dashboard.map((v) => v?.name));
              // if (role[0] === "super_admin") {
              // 	this.$router.addRoutes(apps);
              // 	// this.$router.addRoutes(dashboard);
              // } else {
              // 	this.$router.addRoutes(company_apps);
              // 	this.$router.addRoutes(company_dashboard);
              // }
              useJwt.setRefreshToken(response.data.data.token);
              this.$router
                .replace(getHomeRouteForLoggedInUser(role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Welcome ${userData.name || userData.username}`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `You have successfully logged in as ${role}. Now you can start to explore!`,
                    },
                  });
                });
            })
            .catch((error) => {
              const data = error.response.data.message;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  // title: `Welcome `,
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: data,
                },
              });
            });
        }
      });
    },
    privacy() {
      this.$router.push({
        path: "/privacy",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.bgExampleClass {
  background-image: url("~@/assets/images/login/login.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.loginText {
  color: #38c06c;
  width: 558px;
  height: 53px;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
}
.headText {
  color: #1d1d1d;
  font-size: 25px;
  font-weight: bold;
}
.textInfo {
  color: #38c06c;
  font-size: 16px;
}
.privacy-policy {
  border: 0;
  background: none;
  color: black;
  font-weight: bolder;
  align-self: flex-end;
  display: flex;
  justify-content: end;
  padding-bottom: 1rem;
}
.login {
  height: 95%;
}
</style>
